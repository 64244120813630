import {request} from "./request";
export function getServiceData() {
  return  request({
    params:{
      route:'information/information/getSolve',
      information_id:'4'
    }

  })
};
