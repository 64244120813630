<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/serviceBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{path:switchList.link,query:{index:0}}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--富文本-->
          <div class="WapBoxPadding richText" v-show="activeIndex==0" v-if="serviceData" v-html="serviceData.description">
      <!--      <img src="~assets/images/img/servicePlan.jpg"/>
            <p>系统优化、直击痛点、对症下药</p>-->
          </div>
          <!--富文本 END-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import {getServiceData} from 'network/service';
  export default {
    name: "ServicePlan",
    components: {SwitchBar},
    data() {
      return {
        //当前活跃的块下标
        activeIndex: 0,
        serviceData:null,
      }
    },
    methods: {
      switchItem(index) {
        if (index != this.$route.query.index) {
          this.$router.push({
            path: this.$route.path,
            query: {
              index: index
            }
          })
        }
      }
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index;
        return this.$store.state.navInfo[3];
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title;
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE;
      },
    },
    created() {
      this.activeIndex = this.$route.query.index;
      getServiceData().then(res=>{
        this.serviceData=res.data.solve;
      })
    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
      }
    }
  }
</script>
<style>
  .richText img{
    width: 100%;
    height: auto;
    margin-bottom: .4rem;
  }
  .richText p{
    font-size: .35rem;
    color: #a6a6a6;
    margin-bottom: .4rem;
  }
</style>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }
    .WapBoxPadding{
      padding:0 3%;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .banner{
    width: 100%;
    height: auto;
  }
  .about {
    padding: 1.65rem var(--switchMarginX);
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>